import { createStore } from "vuex";
import AuthStore from "./auth";

const store = createStore({
  modules: {
    auth: AuthStore,
  },
  state() {
    return {
      appName: "Practice One",
      companyName: "MK Software Solutions",
    };
  },
  mutations: {
    updateAppName(state, appName) {
      state.appName = appName;
    },
  },
  actions: {
    updateAppName({ commit }, payload) {
      commit("updateAppName", payload);
    },
  },
  getters: {
    appName(state) {
      return state.appName;
    },
    companyName(state) {
      return state.companyName;
    },
  },
});

export default store;
