<template>
  <div>Simple Blog</div>
  <ProgressSpinner
    style="width:50px;height:50px"
    strokeWidth="8"
    fill="#EEEEEE"
    animationDuration=".5s"
    v-if="isLoading"
  />
  <Markdown v-else :source="source" />
</template>

<script>
import { ref, onMounted } from "vue";
import Markdown from "vue3-markdown-it";
import axios from "axios";
export default {
  components: {
    Markdown,
  },
  setup() {
    const source = ref("");
    const isLoading = ref(true);
    onMounted(async () => {
      var response = await axios.get(
        "/markdowns/Creating_a_Simple_Blog_Using_Vue_with_Markdown.md"
      );
      source.value = response.data;
      isLoading.value = false;
    });
    return {
      source,
      isLoading,
    };
  },
};
</script>

<style scoped>
@keyframes p-progress-spinner-color {
  100%,
  0% {
    stroke: #d62d20;
  }
  40% {
    stroke: #0057e7;
  }
  66% {
    stroke: #008744;
  }
  80%,
  90% {
    stroke: #ffa700;
  }
}
</style>
