<template>
  <!-- <h1>{{ msg }}</h1> -->
  <!-- <slot name="header"></slot> -->
  <!-- <div v-html="source"></div> -->
  <Markdown :source="source" />
  <!-- <Button @click="t" label="Load" /> -->
  <div v-if="isLoggedIn">
    <Button @click="logout" icon="pi pi-sign-out" label="Logout" />
  </div>
  <div v-else>
    <Button @click="login" icon="pi pi-sign-in" label="Login" />
  </div>
  <input type="radio" value="1" v-model="isPrimary" /> Primary
  <input type="radio" value="0" v-model="isPrimary" /> Secondary

  <Button
    label="Show"
    icon="pi pi-external-link"
    @click="openModal"
    v-tooltip.right="'Click to proceed'"
  />
  <Dialog
    header="Header"
    v-model:visible="displayModal"
    :style="{ width: '50vw' }"
    :modal="true"
  >
    <p class="p-m-0">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
      tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
      veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
      commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
      velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
      cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
      est laborum.
    </p>
    <template #footer>
      <Button
        label="No"
        icon="pi pi-times"
        @click="closeModal"
        class="p-button-text"
      />
      <Button label="Yes" icon="pi pi-check" @click="closeModal" autofocus />
    </template>
  </Dialog>
</template>

<script>
import { ref, computed, watch, onMounted, toRefs } from "vue";
import { useStore } from "vuex";
//import foo from "./_mktest.md";
import Markdown from "vue3-markdown-it";
// import axios from "axios";

export default {
  props: ["id"],
  components: {
    Markdown,
    // foo,
  },
  setup(props) {
    const { id } = toRefs(props);
    const store = useStore();
    const isPrimary = ref(1);
    const isLoggedIn = computed(() => store.getters["auth/isAuthenticated"]);
    const displayModal = ref(false);
    const openModal = () => {
      displayModal.value = true;
    };
    const closeModal = () => {
      displayModal.value = false;
    };

    watch(isPrimary, () => {
      store.dispatch(
        "updateAppName",
        isPrimary.value == 1 ? "Practice One" : "Practice Two"
      );
    });
    const login = () => store.dispatch("auth/login");
    const logout = () => store.dispatch("auth/logout");

    const source = ref("");

    onMounted(async () => {
      console.log(id.value);
      source.value = (await import("@/markdowns/" + id.value + ".md")).default;
    });

    return {
      isLoggedIn,
      login,
      logout,
      isPrimary,
      openModal,
      closeModal,
      displayModal,
      source,
    };
  },
};
</script>

<style lang="scss" scoped></style>
