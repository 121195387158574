// import "primevue/resources/themes/saga-blue/theme.css"; //theme
// import "primevue/resources/themes/arya-green/theme.css"; //core css
import "primevue/resources/themes/saga-orange/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css"; //icons
import "../node_modules/highlight.js/styles/monokai.css";

import { createApp } from "vue";
import { MotionOnePlugin } from "vue-motion-one";
import App from "./App.vue";
import store from "./stores";
import router from "./router.js";

import PrrimeVue from "primevue/config";

import Button from "primevue/button";
import Dialog from "primevue/dialog";
import Menubar from "primevue/menubar";
import ProgressSpinner from "primevue/progressspinner";

import Tooltip from "primevue/tooltip";

import Markdown from "vue3-markdown-it";

import { initializeApp } from "firebase/app";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDTf0PZGFa_Af3EEhWOW8R088MHmFcnjJs",
  authDomain: "practicevue-e9a17.firebaseapp.com",
  projectId: "practicevue-e9a17",
  storageBucket: "practicevue-e9a17.appspot.com",
  messagingSenderId: "539752763324",
  appId: "1:539752763324:web:fb113214532bad5e4d25b9",
};

// Initialize Firebase
initializeApp(firebaseConfig);

const app = createApp(App);

app.use(router);
app.use(MotionOnePlugin);
app.use(store);
app.use(PrrimeVue, { ripple: true });
app.use(Markdown);

app.component("Button", Button);
app.component("Dialog", Dialog);
app.component("ProgressSpinner", ProgressSpinner);
app.component("Menubar", Menubar);
app.directive("tooltip", Tooltip);

app.directive("font-size", {
  beforeMount(el, binding, vnode) {
    console.log(binding + " " + vnode);
    var defaultSize;
    if (binding.modifiers.small) {
      defaultSize = 12;
    } else if (binding.modifiers.large) {
      defaultSize = 32;
    } else {
      defaultSize = 16;
    }

    if (binding.modifiers.red) {
      el.style.color = "#ff0000";
    } else if (binding.modifiers.blue) {
      el.style.color = "blue";
    }

    el.style.fontSize = defaultSize + "px";
  },
});

app.mount("#app");
