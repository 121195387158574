<template>
  <the-header></the-header>
  <router-link class="rlink" to="/home">home</router-link>
  <router-link class="rlink" to="/md/mktest">mk test</router-link>
  <router-link class="rlink" to="/md/mktest2/">mk test 2</router-link>
  <router-link class="rlink" to="/md/mktest3/">mk test 3</router-link>
  <router-link class="rlink" to="/test">Test</router-link>
  <router-link class="rlink" to="/simpleblog">Simple Blog</router-link>
  <router-view :key="$route.fullPath"></router-view>

  <!-- <HelloWorld msg="Welcome to Your Vue.js App">
    <template #header>
      <div
        class="description"
        v-animate
        v-font-size.blue
        :keyframes="{
          x: [-500, -0],
        }"
        :options="{
          duration: 3,
        }"
      >
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestias
        repellat repellendus, soluta a in incidunt odio quasi ducimus, aliquam
        sapiente laudantium accusamus? Explicabo modi magni aut sed tempora
        possimus animi?
      </div>
    </template>
  </HelloWorld> -->
  <footer>
    <div>{{ appName }} - {{ company }}</div>
  </footer>
</template>

<script>
// import HelloWorld from "./components/HelloWorld.vue";
import TheHeader from "./components/TheHeader.vue";
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  name: "App",
  components: {
    // HelloWorld,
    TheHeader,
  },
  setup() {
    const store = useStore();
    const appName = computed(() => store.getters.appName);
    const company = computed(() => store.getters.companyName);

    return {
      appName,
      company,
    };
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  /* margin-top: 60px; */
}

footer {
  margin-top: 3em;
}

.description {
  width: 500px;
  margin: 0 auto 10px auto;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 0.8em;
  color: #006362;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 2px 2px 2px lightgray;
}

.rlink {
  padding: 0 10px;
}
</style>
