<template>
  <div>
    Test Page
    <div>
      <ProgressSpinner
        style="width:50px;height:50px"
        strokeWidth="8"
        fill="#EEEEEE"
        animationDuration=".5s"
        v-if="isLoading"
      />
      <Markdown v-else :source="source" />
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { getStorage, ref as fbref, getDownloadURL } from "firebase/storage";

import axios from "axios";
import Markdown from "vue3-markdown-it";
export default {
  props: ["id"],
  components: {
    Markdown,
  },
  setup(props) {
    console.log(props.id);
    const isLoading = ref(true);
    const source = ref("");
    onMounted(async () => {
      const storage = getStorage();
      const url = await getDownloadURL(
        fbref(
          storage,
          "gs://practicevue-e9a17.appspot.com/markdowns/Creating_a_Simple_Blog_Using_Vue_with_Markdown.md"
        )
      );

      let response = await axios.get(url);

      source.value = response.data;
      isLoading.value = false;
    });
    return {
      source,
      isLoading,
    };
  },
};
</script>

<style scoped>
@keyframes p-progress-spinner-color {
  100%,
  0% {
    stroke: #d62d20;
  }
  40% {
    stroke: #0057e7;
  }
  66% {
    stroke: #008744;
  }
  80%,
  90% {
    stroke: #ffa700;
  }
}
</style>
