import { createRouter, createWebHistory } from "vue-router";
import Home from "@/components/Home.vue";
import HelloWorld from "@/components/HelloWorld.vue";
import TestPage from "@/components/TestPage.vue";
import SimpleBlog from "@/components/SimpleBlog.vue";

const routes = [
  { path: "/", redirect: "home" },
  { path: "/home", component: Home },
  { path: "/md/:id", component: HelloWorld, props: true },
  { path: "/test", component: TestPage, props: true },
  { path: "/simpleblog", component: SimpleBlog },
];

var router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
